import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import router from './router'
import UserData from './utils/UserData'
import Vconsole from 'vconsole'


//引入样式
import 'swiper/dist/css/swiper.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'


Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */ )

Vue.prototype.$UserData = UserData
Vue.config.productionTip = false
Vue.use(ElementUI)
    // if (process.env.NODE_ENV == 'development') {
let vConsole = new Vconsole()
Vue.use(vConsole)
    // }
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')