import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserId, getAppUserId } from "../utils/store";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/',
        component: () =>
            import ('../views/pages/login/Entry'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/scan',
        component: () =>
            import ('../views/pages/login/ScanQRCode'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/home',
        component: () =>
            import ('../views/Home'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/login',
        component: () =>
            import ('../views/pages/login/Login'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/link',
        component: () =>
            import ('../views/Link'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/share-collection',
        name: 'PurchaseCollectionLink',
        component: () =>
            import ('../views/pages/share/PurchaseCollectionLink.vue'),
        meta: {
            requiresAuth: false,
            title: '合辑'
        },
    },
    {
        path: '/share-album',
        name: 'PurchaseAlbumLink',
        component: () =>
            import ('../views/pages/share/PurchaseAlbumLink.vue'),
        meta: {
            requiresAuth: false,
            title: '专辑'
        },
    },
    {
        path: '/share-pay',
        name: 'Pay',
        component: () =>
            import ('../views/pages/share/Pay.vue'),
        meta: {
            requiresAuth: false,
            title: '支付'
        },
    },
    {
        //此界面是支付成功之后的回调地址
        path: '/share-pay-success',
        name: 'PaySuccess',
        component: () =>
            import ('../views/pages/share/PaySuccess.vue'),
        meta: {
            requiresAuth: false,
            title: '支付成功'
        },
    },
    {
        path: '/audition-album',
        name: 'AuditionAlbumDetail',
        component: () =>
            import ('../views/pages/audition/AlbumDetail.vue'),
        meta: {
            requiresAuth: false,
            title: '专辑详情'
        },
    },
    {
        path: '/audio',
        name: 'Audio',
        component: () =>
            import ('../views/pages/audition/AudioInfo.vue'),
        meta: {
            requiresAuth: false,
            title: '音频详情'
        },
    },
    {
        path: '/course-apply-application',
        name: 'CourseApplyApplication',
        component: () =>
            import ('../views/pages/course/ApplyApplication.vue'),
        meta: {
            requiresAuth: false,
            title: '课程报名'
        },
    },
    {
        path: '/course',
        name: 'CourseInfo',
        component: () =>
            import ('../views/pages/course/CourseInfo.vue'),
        meta: {
            requiresAuth: false,
            title: '课程'
        },
    },
    {
        path: '/quiz',
        name: 'QuizAnswer',
        component: () =>
            import ('../views/pages/audition/QuizAnswer.vue'),
        meta: {
            requiresAuth: false,
            title: '课程'
        },
    },
    {
        path: '/subscribe',
        name: 'Subscribe',
        component: () =>
            import ('../views/pages/course/Subscribe.vue'),
        meta: {
            requiresAuth: false,
            title: '订阅'
        },
    },
    {
        path: '/apply-success',
        name: 'ApplySuccess',
        component: () =>
            import ('../views/pages/course/ApplySuccess.vue'),
        meta: {
            requiresAuth: false,
            title: '报名成功'
        },
    },
    {
        path: '/course-list',
        name: 'CourseList',
        component: () =>
            import ('../views/pages/course/CourseList.vue'),
        meta: {
            requiresAuth: false,
            title: '课程列表'
        },
    },
    //------------app 家长登录 路由部分----------
    {
        path: '/app-index',
        name: 'AppEntry',
        component: () =>
            import ('../app/entry/index.vue'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/app-login',
        name: 'index',
        component: () =>
            import ('../app/login/index.vue'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/app-home',
        name: 'index',
        component: () =>
            import ('../app/home/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-package',
        name: 'index',
        component: () =>
            import ('../app/packageDetail/index.vue'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/app-collection',
        name: 'index',
        component: () =>
            import ('../app/collectionDetail/index.vue'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/app-webview',
        name: 'index',
        component: () =>
            import ('../app/webview/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-album',
        name: 'index',
        component: () =>
            import ('../app/albumDetail/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-products',
        name: 'index',
        component: () =>
            import ('../app/productList/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-settings',
        name: 'index',
        component: () =>
            import ('../app/settings/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-orderList',
        name: 'index',
        component: () =>
            import ('../app/orderList/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-editNick',
        name: 'AppEditNickName',
        component: () =>
            import ('../app/nickName/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-expTask',
        name: 'index',
        component: () =>
            import ('../app/expTask/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-myWallet',
        name: 'index',
        component: () =>
            import ('../app/wallet/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-wishList',
        name: 'index',
        component: () =>
            import ('../app/wantAlbum/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-feedback',
        name: 'index',
        component: () =>
            import ('../app/feedback/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-report',
        name: 'index',
        component: () =>
            import ('../app/report/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-audio',
        name: 'index',
        component: () =>
            import ('../app/audio/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-recentSongs',
        name: 'index',
        component: () =>
            import ('../app/recentSongs/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-favSongs',
        name: 'index',
        component: () =>
            import ('../app/favSongs/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-quizRecords',
        name: 'index',
        component: () =>
            import ('../app/quizRecords/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-songComment',
        name: 'index',
        component: () =>
            import ('../app/comment/index.vue'),
        meta: {
            requiresAuth: true,
            title: '可能学园'
        },
    },
    {
        path: '/app-groupPurchase',
        name: 'index',
        component: () =>
            import ('../app/groupPurchase/entry.vue'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
    {
        path: '/app-groupPurchase2',
        name: 'index',
        component: () =>
            import ('../app/groupPurchase/index.vue'),
        meta: {
            requiresAuth: false,
            title: '可能学园'
        },
    },
]
const router = new VueRouter({
    linkActiveClass: 'active',
    mode: 'history',
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        let path = to.path
        if (path.indexOf('app-') >= 0) {
            var userId = getAppUserId()
            if (to.meta.requiresAuth && (userId == undefined || userId == '')) {
                next({
                    path: '/app-index'
                })
            } else {
                next()
            }
        } else {
            var userId = getUserId()
            if (to.meta.requiresAuth && (userId == undefined || userId == '')) {
                next({
                    path: '/'
                })
            } else {
                next()
            }
        }
    }
})
export default router