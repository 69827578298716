import Cookies from 'js-cookie'

const USER_ID = 'keneng-fwh-user_id'
const USER_NAME = 'keneng-fwh-user_name'
const OPEN_ID = 'keneng-fwh-open_id'
const UNION_ID = 'keneng-fwh-union_id'
const USER_MOBILE = 'keneng-fwh-user_mobile'

const APP_USER_ID = 'keneng-fwh-app-user_id'
const APP_LOGIN_TOKEN = 'keneng-fwh-app-login_token'
const APP_KID_ID = 'keneng-fwh-app-kid_id'
const APP_PURCHASE_MUST_READ = 'keneng-fwh-app-purchase-must-read'

const CONTENT_ID = 'keneng-fwh-content-id'
const PURCHASE_TYPE = 'keneng-fwh-purchase-type'
const MERCHANT_ID = 'keneng-fwh-merchant-id'
const OAUTH_URL = 'keneng-fwh-oauth-url'

const APP_HOME_TAB = 'keneng-fwh-app-home-tab-index'
const APP_HOME_FRAGMENT_SCHEDULE_TAB = 'keneng-fwh-app-home-fragment-schedule-tab-index'

export function setPurchaseMustRead(read) {
    Cookies.set(APP_PURCHASE_MUST_READ, read)
}
export function getPurchaseMustRead() {
    return Cookies.get(APP_PURCHASE_MUST_READ)
}

export function setHomeFragmentScheduleTabIndex(index) {
    Cookies.set(APP_HOME_FRAGMENT_SCHEDULE_TAB, index)
}

export function getHomeFragmentScheduleTabIndex() {
    return Cookies.get(APP_HOME_FRAGMENT_SCHEDULE_TAB)
}

export function setHomeTabIndex(index) {
    Cookies.set(APP_HOME_TAB, index)
}

export function getHomeTabIndex() {
    return Cookies.get(APP_HOME_TAB)
}

export function setAppKidId(kid_id) {
    Cookies.set(APP_KID_ID, kid_id)
}

export function getAppKidId() {
    return Cookies.get(APP_KID_ID)
}

export function setAppLoginToken(token) {
    Cookies.set(APP_LOGIN_TOKEN, token)
}

export function getAppLoginToken() {
    return Cookies.get(APP_LOGIN_TOKEN)
}

export function setAppUserId(user_id) {
    Cookies.set(APP_USER_ID, user_id)
}

export function getAppUserId() {
    return Cookies.get(APP_USER_ID)
}

export function setOauthUrl(url) {
    Cookies.get(OAUTH_URL, url)
}

export function getOauthUrl() {
    return Cookies.get(OAUTH_URL)
}

export function getContentId() {
    return Cookies.get(CONTENT_ID)
}

export function getMerchantId() {
    return Cookies.get(MERCHANT_ID)
}

export function getPurchaseType() {
    return Cookies.get(PURCHASE_TYPE)
}

export function setContentId(content_id) {
    Cookies.set(CONTENT_ID, content_id)
}

export function setMerchantId(merchant_id) {
    Cookies.set(MERCHANT_ID, merchant_id)
}

export function setPurchaseType(purchase_type) {
    Cookies.set(PURCHASE_TYPE, purchase_type)
}

export function setUnionId(union_id) {
    Cookies.set(UNION_ID, union_id)
}

export function getUnionId() {
    return Cookies.get(UNION_ID)
}

export function setUserMobile(mobile) {
    Cookies.set(USER_MOBILE, mobile)
}

export function getUserMobile() {
    return Cookies.get(USER_MOBILE)
}

export function setOpenId(open_id) {
    Cookies.set(OPEN_ID, open_id)
}

export function getOpenId() {
    return Cookies.get(OPEN_ID)
}


export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function clearUser() {
    Cookies.remove(USER_ID)
    Cookies.remove(USER_NAME)
    Cookies.remove(OPEN_ID)
    Cookies.remove(USER_MOBILE)
}

export function appLogout() {
    Cookies.remove(APP_USER_ID)
    Cookies.remove(APP_LOGIN_TOKEN)
    Cookies.remove(OPEN_ID)
    Cookies.remove(APP_KID_ID)
    Cookies.remove(APP_HOME_TAB)
    Cookies.remove(APP_HOME_FRAGMENT_SCHEDULE_TAB)
    Cookies.remove(APP_PURCHASE_MUST_READ)
}