<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>


export default {
  name: 'app',
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>

</style>
